// Footer.js
import React from 'react';
import './Footer.css';

const Footer = () => {
  return (
    <footer className="footer-container">
      <div className="footer-content">
      <div className="footer-bottom">
        <p>&copy; {(new Date().getFullYear())} Orca Instalações Elétricas Em Rede De Distribuição Ltda.</p>
        <p>Todos os direitos reservados.</p>
      </div>
      </div>
      <div className="footer-section">
          <h4>Contato do desenvolvedor, para sugestões ou erros:</h4>
          <p><a href="mailto:william@orcainstalacoes.com">william@orcainstalacoes.com</a></p>
     </div>
    </footer>
  );
};

export default Footer;