import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AuthProvider } from './components/AuthContext.js';
import ProtectedRoute from './components/ProtectedRoute.js';
import Login from './components/Login.js';
import Register from './components/Register.js';
import Relatorio from './components/Relatorio.js';
import VisualizarRelatorio from './components/visualizar-relatorio.js';
import Admin from './components/Admin.js';
import ListarRelatorios from './components/ListarRelatorios.js';
import NotFound from './components/NotFound.js';

function App() {
  return (
    <AuthProvider>
      <Router>
      <div className="App">
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="admin" element={<ProtectedRoute><Admin /></ProtectedRoute>} />
            <Route path="/relatorios" element={<ProtectedRoute><Relatorio /></ProtectedRoute>} />
            <Route path="/listarrelatorios" element={<ProtectedRoute><ListarRelatorios /></ProtectedRoute>} />
            <Route path="/relatorio/:reportId" element={<ProtectedRoute><VisualizarRelatorio /></ProtectedRoute>} />
            <Route path="*" element={<NotFound />} /> {/* Rota para capturar caminhos não definidos */}
          </Routes>
      </div>
    </Router>
    </AuthProvider>
  );
}

export default App;