import React, { useState } from 'react';
import './Register.css';
import api from './api';

const Register = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();

    setIsLoading(true);

    // Agora envie as informações de registro para o servidor
    try {
      const response = await api.post('/api/register', {
        username: username,
        password: password,
      });

      if (response.status === 200) {
        alert('Usuário registrado com sucesso!');
        setIsLoading(false);
      } else {
        alert('Erro ao registrar usuário');
        setIsLoading(false);
      }
    } catch (error) {
      console.error(`Erro ao registrar usuário: ${error}`);
      alert('Erro ao registrar usuário');
    }
  };

  return (
    <div className="register-container">
    <form onSubmit={handleSubmit}>
      <label>
        Insira seu melhor email:
        <input type="text" value={username} onChange={(e) => setUsername(e.target.value)} required />
      </label>
      <label>
        Crie uma senha:
        <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} required />
      </label>
      <button type="submit" disabled={isLoading}>
          {isLoading ? '' : 'Registrar'}
        </button>
    </form>
    </div>
  );
};

export default Register;
