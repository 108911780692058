import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './Login.css';
import api from './api'; // importa a instância configurada do axios
import { useAuth } from './AuthContext'; // Importando o hook useAuth

const Login = () => {
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { login } = useAuth(); // Utilizando o login do contexto de autenticação

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    const username = event.target.username.value;
    const password = event.target.password.value;

    try {
      const response = await api.post('/api/login', {
        username: username,
        password: password,
      });

      const token = response.headers['authorization']; // Acesso correto ao token
      if (token) {
        localStorage.setItem('token', token); // Armazenando o token
        login(); // Atualizando o estado de autenticação
        navigate('/admin', { replace: true });
      } else {
        throw new Error('Invalid username or password');
      }
    } catch (error) {
      setErrorMessage(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="login-container">
      <form onSubmit={handleSubmit}>
        <label htmlFor="username">Email:</label>
        <input type="text" id="username" name="username" />
        <label htmlFor="password">Senha:</label>
        <input type="password" id="password" name="password" />
        <button type="submit" disabled={isLoading}>{isLoading ? 'Carregando...' : 'Entrar'}</button>
      </form>
      <Link to="/register">Registre-se</Link>
      {errorMessage && <p id="error-message">{errorMessage}</p>}
    </div>
  );
};

export default Login;
