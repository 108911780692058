import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify'; // Supondo que você esteja usando react-toastify
import './style3.css';
import api from './api';

function Relatorio() {
  const [formData, setFormData] = useState(new FormData());
  const [successMessage, setSuccessMessage] = useState(''); // Adicionado
  const [errorMessage, setErrorMessage] = useState(null);
  const [savedFormData, setSavedFormData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleChange = (event) => {
    const { name, value, files } = event.target;

    if (files) {
      formData.append(name, files[0]);
    } else {
      formData.set(name, value);
    }
  };

  console.log('Form data:', formData);

  const saveData = async (data) => {
    const response = await api.post('/relatorio', data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    try {
        const savedData = await saveData(formData);
        toast.success('Relatório enviado com sucesso!');  // Mostrar toast de sucesso
        setSavedFormData(savedData);  // Atualize o estado com os dados enviados
        setSuccessMessage("Dados enviados com sucesso!"); // Atualize o estado com a mensagem de sucesso
    } catch (error) {
        console.error("Erro ao enviar os dados:", error);
        setSuccessMessage("Houve um erro ao enviar os dados."); // Você pode definir uma mensagem de erro também, se quiser
    } finally {
        setIsLoading(false);  // Finalize o loading após envio
    }
  };

  return (
    <div className="reporta-list">
        {/* Mensagem de sucesso */}
        <div className={`toast ${successMessage ? 'show' : ''} success`}>
            {successMessage}
        </div>

        {/* Mensagem de erro */}
        <div className={`toast ${errorMessage ? 'show' : ''} error`}>
            {errorMessage}
        </div>
    <form onSubmit={handleSubmit} encType="multipart/form-data">
      <h2>RELATÓRIO DE INSPEÇÃO DE SEGURANÇA EM CAMPO</h2>
      <label htmlFor="equipe">EQUIPE:</label>
      <input type="text" id="team" name="team" onChange={handleChange} />

      <label htmlFor="cidade">CIDADE:</label>
      <input type="text" id="city" name="city" onChange={handleChange} />

      <label htmlFor="data">DATA:</label>
      <input type="date" id="date" name="date" onChange={handleChange} />

      <label htmlFor="atividade">ATIVIDADE:</label>
      <textarea id="atividade" name="activity" onChange={handleChange}></textarea>

      <label htmlFor="pms">PES:</label>
      <input type="text" id="pms" name="pms" onChange={handleChange} />

      <label htmlFor="area">ÁREA:</label>
      <input type="text" id="area" name="area" onChange={handleChange} />

      <label htmlFor="placaVeiculo">PLACA DO VEÍCULO:</label>
      <input type="text" id="placaVeiculo" name="vehicle_plate" onChange={handleChange} />

      <label htmlFor="horario">HORÁRIO:</label>
      <input type="time" id="horario" name="time" onChange={handleChange} />

      <label htmlFor="si">OC:</label>
      <input type="text" id="si" name="si" onChange={handleChange} />


      <h2>DADOS DA EQUIPE</h2>

        <thead>
          <tr>
            <th>NOME</th>
            <th>MATRÍCULA</th>
            <th>FUNÇÃO</th>
          </tr>
        </thead>
          <tr>
            <td><input type="text" id="nome1" name="team_member_name_1" onChange={handleChange} /></td>
            <td><input type="text" id="matricula1" name="team_member_registration_1" onChange={handleChange} /></td>
            <td><input type="text" id="funcao1" name="team_member_function_1" onChange={handleChange} /></td>
          </tr>
          <tr>
            <td><input type="text" id="nome2" name="team_member_name_2" onChange={handleChange} /></td>
            <td><input type="text" id="matricula2" name="team_member_registration_2" onChange={handleChange} /></td>
            <td><input type="text" id="funcao2" name="team_member_function_2" onChange={handleChange} /></td>
          </tr>
          <tr>
            <td><input type="text" id="nome3" name="team_member_name_3" onChange={handleChange} /></td>
            <td><input type="text" id="matricula3" name="team_member_registration_3" onChange={handleChange} /></td>
            <td><input type="text" id="funcao3" name="team_member_function_3" onChange={handleChange} /></td>
          </tr>
          <tr>
            <td><input type="text" id="nome4" name="team_member_name_4" onChange={handleChange} /></td>
            <td><input type="text" id="matricula4" name="team_member_registration_4" onChange={handleChange} /></td>
            <td><input type="text" id="funcao4" name="team_member_function_4" onChange={handleChange} /></td>
            </tr>
            <tr>
            <td><input type="text" id="nome6" name="team_member_name_6" onChange={handleChange} /></td>
            <td><input type="text" id="matricula6" name="team_member_registration_6" onChange={handleChange} /></td>
            <td><input type="text" id="funcao6" name="team_member_function_6" onChange={handleChange} /></td>
          </tr>
          <tr>
          <td><input type="text" id="nome5" name="team_member_name_5" onChange={handleChange} /></td>
          <td><input type="text" id="matricula5" name="team_member_registration_5" onChange={handleChange} /></td>
          <td><input type="text" id="funcao5" name="team_member_function_5" onChange={handleChange} /></td>
          </tr>
          <div>
          <tr>
            <td><label htmlFor="supervisor">SUPERVISOR:</label>
            <input type="text" id="supervisor" name="supervisor_name" onChange={handleChange} />
          </td>
          </tr>
          <tr>
            <td><label htmlFor="fiscal">SESMT/FISCAL:</label>
            <input type="text" id="fiscal" name="fiscal" onChange={handleChange} />
          </td>
          </tr>
          </div>

        <h2>REGISTRO FOTOGRÁFICO DO SERVIÇO</h2>

        <table>
        <tbody>
            <tr>
            <td></td><label htmlFor="imagem">LOCAL SINALIZADO:</label>
            <td></td>
            <td></td><input type="file" id="imagem1" name="photographic_record_1" onChange={handleChange} />
            <td></td>
            </tr>
            <tr>
            <td></td><label htmlFor="imagem">ATIVIDADE APR:</label>
            <td></td>
            <td></td><input type="file" id="imagem2" name="photographic_record_2" onChange={handleChange} />
            <td></td>
            </tr>
            <tr>
            <td></td><label htmlFor="imagem">FOTO DO SERVIÇO:</label>
            <td></td>
            <td></td><input type="file" id="imagem3" name="photographic_record_3" onChange={handleChange} />
            <td></td>
            </tr>
            <tr>
            <td></td><label htmlFor="imagem">FOTO DO SERVIÇO:</label>
            <td></td>
            <td></td><input type="file" id="imagem4" name="photographic_record_4" onChange={handleChange} />
            <td></td>
            </tr>
            <tr>
            <td></td><label htmlFor="imagem">FOTO DO SERVIÇO:</label>
            <td></td>
            <td></td><input type="file" id="imagem5" name="photographic_record_5" onChange={handleChange} />
            <td></td>
            </tr>
            <tr>
            <td></td><label htmlFor="imagem">FOTO DO SERVIÇO:</label>
            <td></td>
            <td></td><input type="file" id="imagem6" name="photographic_record_6" onChange={handleChange} />
            <td></td>
            </tr>
            <tr>
            <td></td><label htmlFor="imagem">FOTO DO SERVIÇO:</label>
            <td></td>
            <td></td><input type="file" id="imagem7" name="photographic_record_7" onChange={handleChange} />
            <td></td>
            </tr>
            <tr>
            <td></td><label htmlFor="imagem">FOTO DO SERVIÇO:</label>
            <td></td>
            <td></td><input type="file" id="imagem8" name="photographic_record_8" onChange={handleChange} />
            <td></td>
            </tr>
        </tbody>
        </table>
        
        <h2>INCONFORMIDADES</h2>

        <h5>As inconformidades estão descritas nas tabelas abaixo:</h5>
    <div>
    <h2>1.</h2>
    <tr>
      <td>
        <label htmlFor="inconformidade">INCONFORMIDADE:</label>
        <textarea id="inconformidade" name="nonconformity_1" onChange={handleChange}></textarea>
      </td>
      <td>
        <label htmlFor="imagem">EVIDÊNCIA:</label>
        <input type="file" id="imagem" name="nonconformity_1_image" onChange={handleChange} />
      </td>
      <td>
        <label htmlFor="melhoria">MELHORIA:</label>
        <textarea id="melhoria" name="improvement_1" onChange={handleChange}></textarea>
      </td>
    </tr>
    <tr>
      <td><label htmlFor="norma">DESCRIÇÃO DA NORMA:</label>
      <input type="text" id="norma" name="norma" onChange={handleChange} />
      </td>
      </tr>
    <h2>2.</h2>
    <p>Caso não tenha evidências, não preencha os campos abaixo</p>
    <tr>
      <td>
        <label htmlFor="inconformidade">INCONFORMIDADE:</label>
        <textarea id="inconformidade" name="nonconformity_2" onChange={handleChange}></textarea>
      </td>
      <td>
        <label htmlFor="imagem">EVIDÊNCIA:</label>
        <input type="file" id="imagem" name="nonconformity_2_image" onChange={handleChange} />
      </td>
      <td>
        <label htmlFor="melhoria">MELHORIA:</label>
        <textarea id="melhoria" name="improvement_2" onChange={handleChange}></textarea>
      </td>
    </tr>
    <tr>
      <td><label htmlFor="norma_2">DESCRIÇÃO DA NORMA:</label>
      <input type="text" id="norma_2" name="norma_2" onChange={handleChange} />
      </td>
      </tr>
    <h2>3.</h2>
    <p>Caso não tenha evidências, não preencha os campos abaixo</p>
    <tr>
      <td>
        <label htmlFor="inconformidade">INCONFORMIDADE:</label>
        <textarea id="inconformidade" name="nonconformity_3" onChange={handleChange}></textarea>
      </td>
      <td>
        <label htmlFor="imagem">EVIDÊNCIA:</label>
        <input type="file" id="imagem" name="nonconformity_3_image" onChange={handleChange} />
      </td>
      <td>
        <label htmlFor="melhoria">MELHORIA:</label>
        <textarea id="melhoria" name="improvement_3" onChange={handleChange}></textarea>
      </td>
    </tr>
    <tr>
      <td><label htmlFor="norma_3">DESCRIÇÃO DA NORMA:</label>
      <input type="text" id="norma_3" name="norma_3" onChange={handleChange} />
      </td>
    </tr>
    <h2>4.</h2>
    <p>Caso não tenha evidências, não preencha os campos abaixo</p>
    <tr>
      <td>
        <label htmlFor="inconformidade">INCONFORMIDADE:</label>
        <textarea id="inconformidade" name="nonconformity_4" onChange={handleChange}></textarea>
      </td>
      <td>
        <label htmlFor="imagem">EVIDÊNCIA:</label>
        <input type="file" id="imagem" name="nonconformity_4_image" onChange={handleChange} />
      </td>
      <td>
        <label htmlFor="melhoria">MELHORIA:</label>
        <textarea id="melhoria" name="improvement_4" onChange={handleChange}></textarea>
      </td>
    </tr>
    <tr>
      <td><label htmlFor="norma_4">DESCRIÇÃO DA NORMA:</label>
      <input type="text" id="norma_4" name="norma_4" onChange={handleChange} />
      </td>
    </tr>
    <h2>5.</h2>
    <p>Caso não tenha evidências, não preencha os campos abaixo</p>
      <td>
        <label htmlFor="inconformidade">INCONFORMIDADE:</label>
        <textarea id="inconformidade" name="nonconformity_5" onChange={handleChange}></textarea>
      </td>
      <td>
        <label htmlFor="imagem">EVIDÊNCIA:</label>
        <input type="file" id="imagem" name="nonconformity_5_image" onChange={handleChange} />
      </td>
      <td>
        <label htmlFor="melhoria">MELHORIA:</label>
        <textarea id="melhoria" name="improvement_5" onChange={handleChange}></textarea>
      </td>
      <tr>
        <td><label htmlFor="norma_5">DESCRIÇÃO DA NORMA:</label>
        <input type="text" id="norma_5" name="norma_5" onChange={handleChange} />
      </td>
    </tr>
    </div>
      <h2>CONSIDERAÇÕES DAS ATIVIDADES</h2>
      <label htmlFor="consideracoes">Considerações Das Atividades:</label>
      <textarea id="consideracoes" name="activity_considerations" onChange={handleChange}></textarea>
      
      <h3>REUNIÃO COM A EQUIPE APRESENTANDO AS INCONFORMIDADES E PONTOS DE MELHORIA</h3>
      <label htmlFor="reuniao">EVIDÊNCIA COM FOTOS:</label>
      <input type="file" id="imagem" name="evidence_1_image" onChange={handleChange} />
      <input type="file" id="imagem" name="evidence_2_image" onChange={handleChange} />

      <h2>LISTA DE PRESENÇA</h2>
      <label htmlFor="lista de presenca">FOTO DA LISTA DE PRESENTES:</label>
      <input type="file" id="imagem" name="memberspresent_image" onChange={handleChange} />

      <button type="submit" disabled={isLoading}>
    {isLoading ? "Enviando..." : "Enviar"}
     </button>
    </form>
    </div>
  );
}

export default Relatorio;