import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import api from './api';
import './VisualizarRelatorio.css';

const VisualizarRelatorio = () => {
  const { reportId } = useParams();
  const [report, setReport] = useState({});
  const [selectedImage, setSelectedImage] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  async function fetchReport() {
    try {
      const response = await api.get(`/relatorio/${reportId}`);
      setReport(response.data);
    } catch (error) {
      console.error(`Erro ao buscar o relatório ${reportId}:`, error);
    }
  }

  useEffect(() => {
    fetchReport();
  }, [reportId]);

  const openImageModal = (url) => {
    setSelectedImage(url);
    setIsOpen(true);
  };

  const printCertificate = () => {
    window.print();
  };

  return (
    <div>
    <form className="my-css-class">
        <h2>RELATÓRIO DE INSPEÇÃO DE SEGURANÇA EM CAMPO</h2>
        <table>
          <tbody>
            <tr>
              <td>Equipe:</td>
              <td>{report?.team}</td>
            </tr>
            <tr>
              <td>Cidade:</td>
              <td>{report?.city}</td>
            </tr>
            <tr>
              <td>Data:</td>
              <td>{report?.date}</td>
            </tr>
            <tr>
              <td>Atividade:</td>
              <td>{report?.activity}</td>
            </tr>
            <tr>
              <td>PMS:</td>
              <td>{report?.pms}</td>
            </tr>
            <tr>
              <td>Área:</td>
              <td>{report?.area}</td>
            </tr>
            <tr>
              <td>Placa Do Veículo:</td>
              <td>{report?.vehicle_plate}</td>
            </tr>
            <tr>
              <td>Horário:</td>
              <td>{report?.time}</td>
            </tr>
            <tr>
              <td>OC:</td>
              <td>{report?.si}</td>
            </tr>
          </tbody>
        </table>

      <h2>DADOS DA EQUIPE</h2>
        <table>
          <thead>
            <tr>
              <th>Nome</th>
              <th>Matrícula</th>
              <th>Função</th>
            </tr>
          </thead>
          <tbody>
            {[1, 2, 3, 4, 5, 6].map((num) => (
              (report[`team_member_name_${num}`] || report[`team_member_registration_${num}`] || report[`team_member_function_${num}`]) &&
              <tr key={num}>
                <td>{report[`team_member_name_${num}`]}</td>
                <td>{report[`team_member_registration_${num}`]}</td>
                <td>{report[`team_member_function_${num}`]}</td>
              </tr>
            ))}
          </tbody>
        </table>

        <div>
          <table>
          <thead>
            <tr>
              <th>SUPERVISOR:</th>
              <th>SESMT/FISCAL:</th>
            </tr>
          </thead>
          <tbody>
              <tr>
                <td>{report && report.supervisor_name}</td>
                <td>{report && report.fiscal}</td>
              </tr>
          </tbody>
        </table>
      </div>

    <h2>REGISTRO FOTOGRÁFICO DO SERVIÇO</h2>

      <table>
        {[
          {label: "LOCAL SINALIZADO:", alt: "Local Sinalizado 1"},
          {label: "ATIVIDADE APR:", alt: "Atividade APR 1"},
          {label: "FOTO DO SERVIÇO:", alt: "Local Sinalizado 2"},
          {label: "FOTO DO SERVIÇO:", alt: "Atividade APR 2"},
          {label: "FOTO DO SERVIÇO:", alt: "Local Sinalizado 3"},
          {label: "FOTO DO SERVIÇO:", alt: "Atividade APR 3"},
          {label: "FOTO DO SERVIÇO:", alt: "Local Sinalizado 4"},
          {label: "FOTO DO SERVIÇO:", alt: "Atividade APR 4"}
          ].map((item, index) => (
            report[`photographic_record_${index + 1}`] !== null &&
            <tr key={index}>
              <td>{item.label}</td>
              <td>
                <img
                  src={report[`photographic_record_${index + 1}`]}
                  alt={item.alt}
                  onClick={() => openImageModal(report[`photographic_record_${index + 1}`])} // Adicionando o onClick handler
                />
              </td>
            </tr>
          ))}
        </table>

        <h2>INCONFORMIDADES</h2>
      <h5>As inconformidades estão descritas nas tabelas abaixo:</h5>

      {Array.from({ length: 5 }, (_, i) => i).map((num) => (
        report[`nonconformity_${num + 1}`] !== null &&
        <table key={num}>
          <tr>
            <td>INCONFORMIDADE:</td>
            <td>{report[`nonconformity_${num + 1}`]}</td>
          </tr>
          <tr>
            <td>EVIDÊNCIA:</td>
            <td>
              <img
                src={report[`nonconformity_${num + 1}_image`]}
                alt={`Inconformidade ${num + 1}`}
                onClick={() => openImageModal(report[`nonconformity_${num + 1}_image`])}
              />
            </td>
          </tr>
          <tr>
            <td>MELHORIA:</td>
            <td>{report[`improvement_${num + 1}`]}</td>
          </tr>
          <tr>
            <td>Descrição Da Norma:</td>
            <td>{num === 0 ? report.norma : report[`norma_${num + 1}`]}</td>
          </tr>
        </table>
      ))}

      <h2>CONSIDERAÇÕES DAS ATIVIDADES</h2>
      <table>
      <tr>
        <td>{report && report.activity_considerations}</td>
      </tr>
      </table>

      <h2>REUNIÃO COM A EQUIPE APRESENTANDO AS INCONFORMIDADES E PONTOS DE MELHORIA</h2>
      <table>
        <tbody>
          <tr>
            <td>
              <img 
                src={report?.evidence_1_image} 
                alt="Evidência imagem 1" 
                onClick={() => openImageModal(report?.evidence_1_image)}
              />
            </td>
          </tr>
          <tr>
            <td>
              <img 
                src={report?.evidence_2_image} 
                alt="Evidência imagem 2" 
                onClick={() => openImageModal(report?.evidence_2_image)}
              />
            </td>
          </tr>
        </tbody>
      </table>

      <h2>LISTA DE PRESENÇA</h2>
      <table>
        <tbody>
          <tr>
            <td>
              <img 
                src={report?.memberspresent_image} 
                alt="Imagem presentes" 
                onClick={() => openImageModal(report?.memberspresent_image)}
              />
            </td>
          </tr>
        </tbody>
      </table>
      <button onClick={printCertificate} className="search-button print-button">Imprimir Relatório</button>
    </form>
          {isOpen && (
            <Lightbox
              mainSrc={selectedImage}
              onCloseRequest={() => setIsOpen(false)}
            />
          )}
        </div>
      );
    }

export default VisualizarRelatorio;



