import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './ReportList.css';
import api from './api';
import Pagination from './Pagination';
// Importa o componente Spinner ou uma animação de carregamento
import Loader from './Loader';

const ListarRelatorios = () => {
  const [allReports, setAllReports] = useState([]);
  const [isLoading, setIsLoading] = useState(false); // Adicionado para controlar o estado de carregamento
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [displayedReports, setDisplayedReports] = useState([]);
  const reportsPerPage = 12;

  useEffect(() => {
    async function fetchAllReports() {
      setIsLoading(true); // Ativa o estado de carregamento
      try {
        const response = await api.get('/relatorios');
        setAllReports(response.data);
        setDisplayedReports(response.data);
      } catch (error) {
        console.error('Erro ao buscar todos os relatórios:', error);
      } finally {
        setIsLoading(false); // Desativa o estado de carregamento após a busca
      }
    }
    fetchAllReports();
  }, []);

  const handleSearch = () => {
    setIsLoading(true); // Ativa o estado de carregamento durante a busca
    if (startDate || endDate) {
      const start = startDate ? new Date(startDate) : new Date(-8640000000000000);
      const end = endDate ? new Date(endDate) : new Date(8640000000000000);
      const filtered = allReports.filter(report => {
        const reportDate = new Date(report.date);
        return reportDate >= start && reportDate <= end;
      });
      setDisplayedReports(filtered);
    } else {
      // Se nenhuma data for fornecida, mostrar todos os relatórios
      setDisplayedReports(allReports);
    }
    setCurrentPage(1);
    setIsLoading(false); // Desativa o estado de carregamento após a busca
  };

  const getCurrentReports = () => {
    const indexOfLastReport = currentPage * reportsPerPage;
    const indexOfFirstReport = indexOfLastReport - reportsPerPage;
    return displayedReports.slice(indexOfFirstReport, indexOfLastReport);
  };

  return (
    <div className="report-list">
      <h2>Relatórios Enviados</h2>
      <div className="search-section">
        <input
          type="date"
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
          placeholder="Data Inicial"
          className="date-input"
        />
        <input
          type="date"
          value={endDate}
          onChange={(e) => setEndDate(e.target.value)}
          placeholder="Data Final"
          className="date-input"
        />
        <button className="search-button" onClick={handleSearch}>Buscar</button>
      </div>
      {isLoading ? (
        <Loader /> // Exibe o componente de carregamento enquanto isLoading for verdadeiro
      ) : (
        <ul>
          {getCurrentReports().map(({ id, team, supervisor_name, date, activity, fiscal, nonconformity_1 }) => (
            <li key={id}>
              EQUIPE: {team} - SUPERVISOR: {supervisor_name} - DATA: {date} - ATIVIDADE: {activity} - SESMT: {fiscal} - INCONFORMIDADE: {nonconformity_1}
              <Link to={`/relatorio/${id}`}>Visualizar relatório</Link>
            </li>
          ))}
        </ul>
      )}
      <Pagination
        reportsPerPage={reportsPerPage}
        totalReports={displayedReports.length}
        paginate={setCurrentPage}
        currentPage={currentPage}
      />
    </div>
  );
};

export default ListarRelatorios;
