import React from 'react';
import { Link } from 'react-router-dom';
import './Admin.css';
import { useAuth } from './AuthContext';
import { useNavigate } from 'react-router-dom';
import Footer from './Footer';

const Admin = () => {
  const { logout } = useAuth();
  const navigate = useNavigate();

  const handleLogout = () => {
    logout();
    navigate('/');
  };

  return (
    <div className="admin-container">
      <header className="header">
        <h1>Relatório De Inspeção De Segurança Em Campo</h1>
      </header>
      <aside className="sidebar">
        <nav>
          <ul>
            <li>
              <Link to="/relatorios" className="nav-link">
                <i className="fas fa-file-upload"></i>Novo Relatório
              </Link>
            </li>
            <li>
              <Link to="/listarrelatorios" className="nav-link">
                <i className="fas fa-clipboard-list"></i>Relatórios Enviados
              </Link>
            </li>
            <div></div>
            <div>
              <button onClick={handleLogout} className="logout-button">
                <i className="fas fa-sign-out-alt"></i>Sair
              </button>
            </div>
          </ul>
        </nav>
        <Footer />
      </aside>
    </div>
  );
};

export default Admin;
